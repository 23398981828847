/**
 * Customer facing statuses for new hire tasks
 */
export enum StatusEnum {
  HIDDEN = "Hidden",
  LOADING = "Loading",
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  API_ERROR = "N/A",
  ERROR = "Error",
  PENDING_REVIEW = "Pending Review By Amazon",
  ELIGIBLE = "Eligible",
  INELIGIBLE = "Ineligible",
  INITIATED = "Initiated",
  NO_ACTION_REQUIRED = "No Action Required",
  NOT_REQUIRED = "Not Required",
  NOT_AVAILABLE_YET = "Not Available Yet",
  ACTION_REQUIRED = "Action Required",
  PENDING = "Pending",
  REJECTED = "Rejected",
  AWAITING_CANDIDATE_INFO = "Awaiting Candidate Info",
  NHE_UNKNOWN = "UNKNOWN",
  NHE_RESERVED = "RESERVED",
  NHE_COMPLETED = "COMPLETED",
  SKIPPED = "SKIPPED",
}

/**
 * DTO for a status
 */
export interface Status {
  status: StatusEnum;
}

export interface BadgePhotoStatus {
  status: StatusEnum;
  badgePhotoSrc: string;
}

// Caas Enum for DT https://code.amazon.com/packages/HVHCAASLambda/blobs/e2a88e605d0b70310feb7f529cfb9db513e4ef8b/--/src/model/enums/status.ts#L29
export enum CaasDrugTestEnum {
  INITIALIZED = "INITIALIZED", // Created in ECOC flow when a drug test is initialized
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  INPROGRESS = "INPROGRESS",
  ENQUEUED = "ENQUEUED", // Created in create order flow, when a drug test is enqueued to be submitted to Quest
  SUBMITTED = "SUBMITTED", // In create order flow,Updated when an order is submitted to Quest
  INPROCESS = "INPROCESS", // Status from Quest, it might be obsolete
  COLLECTED = "COLLECTED", // Specimen collected in ECOC flow
  SUSPENDED = "SUSPENDED", // Status from Quest, it might be obsolete
  ATLAB = "ATLAB", // Status from Quest, it might be obsolete
  PENDINGMRO = "PENDINGMRO", // Status from Quest, it might be obsolete
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  EXPIRED = "Expired",
}

export enum DT_REUSE_TYPE {
  ALUMNI_DT_365 = "Alumni_DT_365",
}

export enum SalesforceStatusEnum {
  COMPLETE = "Complete",
  PENDING = "Pending",
  ERROR = "Error",
}

export enum SalesforceApplicationStepEnum {
  UNKNOWN = "UNKNOWN",
}

export enum NHEAppointmentStatusEnum {
  UNKNOWN = "UNKNOWN",
  COMPLETED = "COMPLETED",
  ARRIVED = "ARRIVED",
  RESERVED = "RESERVED",
  NO_SHOW = "NO_SHOW",
  RELEASED = "RELEASED",
  REJECTED = "REJECTED",
  ON_HOLD = "ON_HOLD",
}

export enum NHEAppointmentApplicationTypeEnum {
  RIGHT_TO_WORK = "RIGHT_TO_WORK",
  NEW_HIRE_EVENT = "NEW_HIRE_EVENT",
  DRUG_TEST = "DRUG_TEST",
}

export enum ActiveNHEAppointmentStatusEnum {
  COMPLETED = "COMPLETED",
  ARRIVED = "ARRIVED",
  RESERVED = "RESERVED",
  NO_SHOW = "NO_SHOW",
}

export enum BackgroundCheckStatus {
  COMPLETED = "Completed",
  PENDING = "Pending",
  IN_PROGRESS = "InProgress",
  AWAITING_CANDIDATE_INFO = "Awaiting Candidate Info", //https://code.amazon.com/packages/HVHCAASLambda/blobs/0ba88f00ad04cc89f346b9355e6250f1a1b91251/--/src/model/enums/status.ts#L88
  INITIATED = "Initiated",
  DISPUTE = "Dispute",
  REQUEST_INITIATED = "Request Initiated",
  ERROR = "Error",
  CANCELLED = "Cancelled",
  NOT_REQUIRED = "Not Required",
}

export enum BadgePhotoUploadStatus {
  SUCCESS = "success",
  SKIP = "skip",
}

export enum MedicalCheckStatus {
  COMPLETED = "Completed",
  IN_PROGRESS = "InProgress",
  ERROR = "Error",
  INITIATED = "Initiated",
  EXPIRED = "Expired",
}

export enum RightToWorkStatus {
  COMPLETED = "Completed",
  IN_PROGRESS = "InProgress",
  ERROR = "Error",
  INITIATED = "Initiated",
  EXPIRED = "Expired",
  REJECTED = "Rejected",
  // UK Additional RightToWorkStatus
  ORDER_PLACED_AT_VENDOR = "OrderPlacedAtVendor",
  AWAITING_CANDIDATE_INFO = "Awaiting Candidate Info",
  PENDING_MANUAL_CHECK = "PendingManualCheck",
  PENDING_F2F_CHECK = "PendingF2FCheck",
  PENDING_REJECTION_REVIEW = "PendingRejectionReview", // Mapped from rtwAuditStatus.Pending
}

export enum RtwAuditStatus {
  NOT_APPLICABLE = "NotApplicable",
  COMPLETED = "Completed",
  PENDING = "Pending",
}

// https://issues.amazon.com/issues/MEW-614
// https://issues.amazon.com/issues/MEW-1537
export const BACKGROUND_CHECK_STATUSES: {
  [k in BackgroundCheckStatus]: StatusEnum;
} = {
  [BackgroundCheckStatus.COMPLETED]: StatusEnum.COMPLETED,
  [BackgroundCheckStatus.CANCELLED]: StatusEnum.ERROR,
  [BackgroundCheckStatus.IN_PROGRESS]: StatusEnum.IN_PROGRESS,
  [BackgroundCheckStatus.PENDING]: StatusEnum.IN_PROGRESS,
  [BackgroundCheckStatus.AWAITING_CANDIDATE_INFO]: StatusEnum.AWAITING_CANDIDATE_INFO,
  [BackgroundCheckStatus.INITIATED]: StatusEnum.INITIATED,
  [BackgroundCheckStatus.DISPUTE]: StatusEnum.IN_PROGRESS,
  [BackgroundCheckStatus.NOT_REQUIRED]: StatusEnum.NOT_REQUIRED,
  [BackgroundCheckStatus.REQUEST_INITIATED]: StatusEnum.IN_PROGRESS,
  [BackgroundCheckStatus.ERROR]: StatusEnum.ERROR, // 'Error' status is temporary and will be resolved by the recruiter
};

export const MEDICAL_CHECK_STATUSES: {
  [status in MedicalCheckStatus]: StatusEnum;
} = {
  [MedicalCheckStatus.COMPLETED]: StatusEnum.COMPLETED,
  [MedicalCheckStatus.IN_PROGRESS]: StatusEnum.IN_PROGRESS,
  [MedicalCheckStatus.ERROR]: StatusEnum.ERROR,
  [MedicalCheckStatus.INITIATED]: StatusEnum.IN_PROGRESS,
  [MedicalCheckStatus.EXPIRED]: StatusEnum.NOT_STARTED,
};

export const RIGHT_TO_WORK_STATUSES: {
  [status in RightToWorkStatus]: StatusEnum;
} = {
  [RightToWorkStatus.COMPLETED]: StatusEnum.COMPLETED,
  [RightToWorkStatus.IN_PROGRESS]: StatusEnum.IN_PROGRESS,
  [RightToWorkStatus.ERROR]: StatusEnum.ERROR,
  [RightToWorkStatus.INITIATED]: StatusEnum.IN_PROGRESS,
  [RightToWorkStatus.EXPIRED]: StatusEnum.NOT_STARTED,
  [RightToWorkStatus.REJECTED]: StatusEnum.IN_PROGRESS,
  // UK Additional RightToWorkStatus
  [RightToWorkStatus.ORDER_PLACED_AT_VENDOR]: StatusEnum.IN_PROGRESS,
  [RightToWorkStatus.AWAITING_CANDIDATE_INFO]: StatusEnum.IN_PROGRESS,
  [RightToWorkStatus.PENDING_MANUAL_CHECK]: StatusEnum.IN_PROGRESS,
  [RightToWorkStatus.PENDING_F2F_CHECK]: StatusEnum.IN_PROGRESS,
  [RightToWorkStatus.PENDING_REJECTION_REVIEW]: StatusEnum.IN_PROGRESS,
};

export const CAAS_DRUG_TEST_STATUSES: {
  [status in CaasDrugTestEnum]: StatusEnum;
} = {
  [CaasDrugTestEnum.INITIALIZED]: StatusEnum.INITIATED,
  [CaasDrugTestEnum.CREATED]: StatusEnum.INITIATED,
  [CaasDrugTestEnum.EXPIRED]: StatusEnum.NOT_STARTED,
  [CaasDrugTestEnum.IN_PROGRESS]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.PENDING]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.INPROGRESS]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.ENQUEUED]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.SUBMITTED]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.INPROCESS]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.COLLECTED]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.SUSPENDED]: StatusEnum.ERROR,
  [CaasDrugTestEnum.COMPLETED]: StatusEnum.COMPLETED,
  [CaasDrugTestEnum.CANCELLED]: StatusEnum.NOT_STARTED,
  [CaasDrugTestEnum.ATLAB]: StatusEnum.IN_PROGRESS,
  [CaasDrugTestEnum.PENDINGMRO]: StatusEnum.IN_PROGRESS,
};
